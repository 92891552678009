import React, { useRef, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import data from '../../data.json';
import emailjs from '@emailjs/browser';
import '../../App.css';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import PrivacyModal from '../Modal.js';
import { toast } from 'react-toastify';

function FormPage() {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('el');
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${data.mallikatso.serviceId}`,
        `${data.mallikatso.templateId}`,
        form.current,
        {
          publicKey: `${data.mallikatso.publicKey}`
        }
      )
      .then(
        () => {
          console.log('SUCCESS!');
          toast.success(t('Your email was sent successfully!'), {
            position: 'top-right'
          });
          setFormData({
            name: '',
            email: '',
            message: '',
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error(t('Error, please try again!'), {
            position: 'top-right'
          });
        }
      );
  };

  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: '',
    agree: false // Default value for the agreement
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAgreeChange = (e) => {
    const checked = e.target.checked;
    setFormData({ ...formData, agree: checked });
  };

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const togglePrivacyModal = () => {
    setIsPrivacyModalOpen(!isPrivacyModalOpen);
  };

  return (
    <Container fluid="sm">
      <div className="App">
        <div className="App-header Form-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
          <a href={`/clients/${data.mallikatso.url}`}>
            <Image
              fluid
              alt={data.mallikatso.alt}
              src={data.mallikatso.img}
              className="img-border p-3 pt-5"
            />
          </a>
        </div>
        <div className="App-body col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto pt-4">
          <p className="col-11 col-sm-12 col-md-10 col-lg-9 col-xl-8 col-xxl-10">
            {t('write-review')}
          </p>
          {/* <p className="col-11 col-sm-12 col-md-10 col-lg-9 col-xl-8 col-xxl-10">
            {t('do-best')}
          </p> */}
        </div>
        <div className="App-form col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
          <form ref={form} onSubmit={sendEmail}>
            <div className="pb-2 col-10 col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8 d-flex flex-column mx-auto">
              <label
                htmlFor="name"
                className="form-label d-flex align-items-center"
              >
                 {t('form-name')}
              </label>
              <input
                type="text"
                className="form-control p-2"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="pb-2 col-10 col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8 d-flex flex-column mx-auto">
              <label
                htmlFor="email"
                className="form-label d-flex align-items-center"
              >
                 Email:
              </label>
              <input
                type="email"
                className="form-control p-2"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="pb-2 col-10 col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8 d-flex flex-column mx-auto">
              <label
                htmlFor="message"
                className="form-label d-flex align-items-center"
              >
                {t('form-review')}
              </label>
              <textarea
                className="form-control p-2"
                id="message"
                name="message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="hidden">
              <textarea
                onChange={handleChange}
                value={data.mallikatso.name}
                id="client"
                name="client"
              ></textarea>
            </div>
            <div className="pb-2 col-10 col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8 d-flex flex-row align-items-center mx-auto">
              <Form.Check
                className="me-2"
                type="checkbox"
                id="agree"
                checked={formData.agree}
                onChange={handleAgreeChange}
                required
              />
              {t('i-agree')}
              <span>&nbsp;</span>
              <Button
                className="App-body text-danger p-0"
                variant="link"
                onClick={togglePrivacyModal}
              >
                {t('privacy-main')}
              </Button>
            </div>
            <div className="pb-2 col-10 col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8 d-flex flex-row mx-auto justify-content-end">
              <Button
                type="submit"
                variant="link"
                size="lg"
                href="/clients/mallikatso"
                className="Back-button col-5 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2"
              >
                {t('back')}
              </Button>
              <Button
                type="submit"
                disabled={!formData.agree}
                variant="primary"
                size="lg"
                className="Yes-button col-5 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2"
              >
                {t('send')}
              </Button>
            </div>
            <div className="App-body col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto pt-2">
              <p className="col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 col-xxl-10 small-text">
                {t('your-review')}
              </p>
            </div>
          </form>
        </div>
        <div className="App-body position-absolute bottom-0 end-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
            <Button
              className="App-body text-danger p-0 color-text-footer"
              variant="link"
              style={{ textDecoration: 'none' }}
            >
              <a
                href="https://www.hotelfeedback.gr"
                className='color-text-footer'
                style={{ textDecoration: 'none' }}
              >
                {t('powered')}
              </a>
            </Button>
          </div>
      </div>
      <PrivacyModal open={isPrivacyModalOpen} onClose={togglePrivacyModal} />
    </Container>
  );
}

export default FormPage;
