import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ReviewPage from './pages/ReviewPage.js';
// import FormPage from './pages/FormPage.js';
import LordosBeachHotelPage from './pages/clients/LordosBeachHotel.js'
import CavoZoePage from './pages/clients/CavoZoe.js';
import IsonJewelsPage from './pages/clients/IsonJewels.js';
import AkademiaPage from './pages/clients/Akademia.js';
import SaltyPage from './pages/clients/Salty.js';
// import MuppetsPage from './pages/clients/Muppets.js';
// import AkademiaFormPage from './pages/forms/AkademiaForm.js';
// import SaltyFormPage from './pages/forms/SaltyForm.js';
import IsonJewelsFormPage from './pages/forms/IsonJewelsForm.js';
// import CavoZoeFormPage from './pages/forms/CavoZoeForm.js';
// import MuppetsFormPage from './pages/forms/MuppetsForm.js';
import MallikatsoPage from './pages/clients/Mallikatso.js';
import MallikatsoFormPage from './pages/forms/MallikatsoForm.js';
import EvasApartmentsPage from './pages/clients/EvasApartments.js';
import EvasApartmentsFormPage from './pages/forms/EvasApartmentsForm.js';
import GoldenBayPage from './pages/clients/GoldenBay.js';
import PavloNapaPage from './pages/clients/PavloNapa.js';
import PrivacyPolicyPage from './pages/PrivacyPolicy.js';
import NotFoundPage from './pages/NotFound.js';
import Animate from 'react-smooth';

function App() {
  return (
    <Router>
      <Animate to="1" from="0" attributeName="opacity">
        <div>
          <Routes>
            <Route exact path="/" element={<ReviewPage />} />
            <Route exact path="*" element={<NotFoundPage />} />

            <Route exact path="/clients/cavozoe" element={<CavoZoePage />} />
            <Route exact path="/clients/isonjewels" element={<IsonJewelsPage />} />
            <Route exact path="/clients/mallikatso" element={<MallikatsoPage />} />
            <Route exact path="/clients/evasapartments" element={<EvasApartmentsPage />} />
            <Route exact path="/clients/goldenbay" element={<GoldenBayPage />} />
            <Route exact path="/clients/pavlonapa" element={<PavloNapaPage />} />
            <Route exact path="/clients/akademia" element={<AkademiaPage />} />
            <Route exact path="/clients/lordosbeachhotel" element={<LordosBeachHotelPage />} />
            <Route exact path="/clients/salty" element={<SaltyPage />} />

            <Route exact path="/form/isonjewels" element={<IsonJewelsFormPage />} />
            <Route exact path="/form/mallikatso" element={<MallikatsoFormPage />} />
            <Route exact path="/form/evasapartments" element={<EvasApartmentsFormPage />} />

            <Route exact path="/privacypolicy" element={<PrivacyPolicyPage />} />

            {/* <Route exact path="/form" element={<FormPage />} /> */}
            {/* <Route exact path="/clients/muppets-skg" element={<MuppetsPage />} /> */}
            {/* <Route exact path="/form/akademia" element={<AkademiaFormPage />} /> */}
            {/* <Route exact path="/form/salty" element={<SaltyFormPage />} /> */}
            {/* <Route exact path="/form/cavozoe" element={<CavoZoeFormPage />} /> */}
            {/* <Route exact path="/form/muppets-skg" element={<MuppetsFormPage />} /> */}
          </Routes>
        </div>
        <ToastContainer />
      </Animate>
    </Router>
  );
}

export default App;
