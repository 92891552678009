import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import data from '../data.json';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function ReviewPage() {
  const navigate = useNavigate();

  return (
    <Container fluid="sm">
      <div className="container">
        <div className="App">
          <div className="App-header col-12 pt-4">
            <p className="fw-bold">Visitors Feedback</p>
            <Image
              fluid
              alt="hotelfeedback"
              src={data.error.image}
              className="pb-4 img-error"
            />
            <div className="App-body col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto pt-3">
              <p className="fw-bold">PAGE NOT FOUND</p>
              <Button
                type="submit"
                variant="outlined"
                size="lg"
                onClick={() => navigate(-1)}
                className="fw-bold col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2 col-xxl-2 mx-auto mb-4 mt-2 Yes-button"
              >
                Go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ReviewPage;
