import React from 'react';
import Button from 'react-bootstrap/Button';
import '../App.css';
import '../i18n';
import { useTranslation } from 'react-i18next';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter
} from 'mdb-react-ui-kit';
import PrivacyPolicyPage from './PrivacyPolicy';

function Modal({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <MDBModal open={open} onClose={onClose} tabIndex="-1">
      <MDBModalDialog scrollable size="fullscreen-sm-down">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{t('Privacy Policy')}</MDBModalTitle>
            <button className="btn-close" onClick={onClose}></button>
          </MDBModalHeader>
          <MDBModalBody>
            <PrivacyPolicyPage />
          </MDBModalBody>
          <MDBModalFooter>
            <Button color="secondary" style={{ fontWeight: "500" }} onClick={onClose}>
              {t('Close')}
            </Button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default Modal;
