import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import data from '../data.json';
import '../App.css';
import "../i18n";
import { useTranslation } from 'react-i18next';
import PrivacyModal from './Modal.js';

function Visitors() {
  const {t} = useTranslation();

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const togglePrivacyModal = () => {
    setIsPrivacyModalOpen(!isPrivacyModalOpen);
  };

  return (
    <Container fluid="sm">
      <div className="container">
        <div className="App">
          <div className="App-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
            <Image
              fluid
              alt={data.visitors.alt}
              src={data.visitors.img}
              className="img-border"
            />
          </div>
          <div className="App-body col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
            <div className="responsive-height">
              <p>Our clients:</p>
              <a href="/clients/salty">{data.salty.name}</a>
              <br />

              <a href="/clients/akademia">{data.akademia.name}</a>
              <br />

              <a href="/clients/cavozoe">{data.cavo.name}</a>
              <br />

              <a href="/clients/goldenbay">{data.golden.name}</a>
              <br />

              <a href="/clients/pavlonapa">{data.pavlo.name}</a>
              <br />

              <a href="/clients/isonjewels">{data.ison.name}</a>
              <br />

              <a href="/clients/mallikatso">{data.mallikatso.name}</a>
              <br />

              <a href="/clients/evasapartments">{data.evas.name}</a>
              <br />

              <a href="/clients/lordosbeachhotel">{data.lordos.name}</a>
              <br />
            </div>
          </div>
          <div className="App-body position-absolute bottom-0 end-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
            <Button
              className="App-body p-0 color-text-footer"
              variant="link"
              onClick={togglePrivacyModal}
              style={{ textDecoration: 'none' }}
            >
              {t('privacy-policy')}
            </Button>
            <Button
              className="App-body text-danger p-0"
              variant="link color-text-footer"
              style={{ textDecoration: 'none' }}
            >
              <a
                href="https://www.hotelfeedback.gr"
                className='color-text-footer'
                style={{ textDecoration: 'none' }}
              >
                {t('powered')}
              </a>
            </Button>
          </div>
        </div>
      </div>
      <PrivacyModal open={isPrivacyModalOpen} onClose={togglePrivacyModal} />
    </Container>
  );
}

export default Visitors;
