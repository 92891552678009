import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import '../App.css';
import Image from 'react-bootstrap/Image';
import data from '../data.json';
import { useTranslation } from 'react-i18next';

function PrivacyPolicyPage() {
  const {t} = useTranslation();
  return (
    <Container fluid="sm">
      <div className="App">
        <div className="App-header Form-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
          <a href={`/`}>
            <Image
              fluid
              alt={data.visitors.alt}
              src={data.visitors.img}
              className="img-border p-2"
            />
          </a>
        </div>
        <div className="App-body Justify col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
          <p className="pt-2">Last updated: May 2, 2024</p>
          <p className="pt-2 fw-bold">{t('privacy-title')}</p>
          <p className="pt-2">{t('privacy-head')}</p>
          <ol>
            <li>{t('privacy-first')}</li>
            <li>{t('privacy-second')}</li>
            <li>{t('privacy-third')}</li>
            <li>{t('privacy-fourth')}</li>
            <li>{t('privacy-fifth')}</li>
            <li>{t('privacy-sixth')}</li>
            <li>{t('privacy-seventh')}</li>
          </ol>
          <p>{t('privacy-footer-first')}</p>
          <p>{t('privacy-footer-second')}</p>
          <p>{t('privacy-footer-third')}</p>
          <a href="https://www.hotelfeedback.gr">www.hotelfeedback.gr</a>
        </div>
      </div>
    </Container>
  );
}
export default PrivacyPolicyPage;
