import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import data from '../../data.json';
import '../../App.css';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import PrivacyModal from '../Modal';

function ReviewPage() {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const togglePrivacyModal = () => {
    setIsPrivacyModalOpen(!isPrivacyModalOpen);
  };

  return (
    <Container fluid="sm">
      <div className="container">
        <div className="App">
          <div className="App-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
            <Image
              fluid
              style={{ minHeight: '210px' }}
              alt={data.lordos.alt}
              src={data.lordos.img}
              className="img-border"
            />
          </div>
          <div className="App-body col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto pt-2">
            <p className='bold'>{t('are-you-happy')}</p>
            <Button
              type="submit"
              variant="secondary"
              href={data.lordos.link}
              target="_blank"
              rel="noopener"
              className="fw-bold col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1 col-xxl-1 mx-auto mb-4 mt-2 Yes-button"
            >
              {t('yes')}
            </Button>
            <Button
              type="submit"
              variant="secondary"
              href="https://app.hotelfeedback.gr/h/lordos-beach-hotel---spa/quickreview"
              target="_self"
              rel="noopener"
              className="fw-bold col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1 col-xxl-1 mx-auto mb-4 mt-2 Yes-button"
            >
              {t('not-happy')}
            </Button>
          </div>
          <div className="App-body position-absolute bottom-0 end-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
            <Button
              className="App-body p-0 color-text-footer"
              variant="link"
              onClick={togglePrivacyModal}
              style={{ textDecoration: 'none' }}
            >
              {t('privacy-policy')}
            </Button>
            <Button
              className="App-body text-danger p-0 color-text-footer"
              variant="link"
              style={{ textDecoration: 'none' }}
            >
              <a
                href="https://www.hotelfeedback.gr"
                className="color-text-footer"
                style={{ textDecoration: 'none' }}
              >
                {t('powered')}
              </a>
            </Button>
          </div>
        </div>
      </div>
      <PrivacyModal open={isPrivacyModalOpen} onClose={togglePrivacyModal} />
    </Container>
  );
}

export default ReviewPage;
